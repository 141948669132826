import React, { useState, useEffect } from "react";
import { Button, Card, Row } from "reactstrap";
import Switch from "rc-switch";
import {
  Colxx,
  Separator,
} from "../../../../../components/common/CustomBootstrap";
import DatePicker from "react-datepicker";
import { MDBDataTable } from "mdbreact";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import {
  addEnquireDataAction,
  deleteEnquireDataAction,
  getAllCourseDataAction,
  getEnquireDataAction,
  updateEnquireDataAction,
} from "../../../../../redux/master/actions";
import AlertMessage from "../../../../../helpers/alerts";
import DeleteConfirmationPopUp from "../../../../../components/common/deleteConfirmationPopup";
import {
  formateDate,
  requiredFieldValidation,
} from "../../../../../helpers/Utils";

const Enquires = (props) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [enqueryStatus, setEnqueryStatus] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [courseSelectOption, setCourseSelectOption] = useState([]);
  let defaultValues = {
    name: "",
    email: "",
    mobile: "",
    institute: "",
    course_name: "",
    course: "",
    message: "",
    remark: "",
    next_followup_date: "",
    is_closed: false,
  };
  const [values, setValues] = useState(defaultValues);

  const dispatch = useDispatch();
  const fetchedData = useSelector((state) => state.master.allEnquires);
  const isApiSuccess = useSelector((state) => state.master.isApiSuccess);
  const allCourses = useSelector((state) => state.master.allCourses);

  useEffect(() => {
    let allOptions = [];
    allCourses.map((data, index) => {
      allOptions.push({ label: data.name, value: data.id, key: index });
    });

    setCourseSelectOption(allOptions);
  }, [allCourses]);

  const [datatable, setDatatable] = React.useState({
    columns: [
      {
        label: "#",
        field: "sn",
        width: 50,
        attributes: {
          "aria-controls": "DataTable",
          "aria-label": "id",
        },
        sort: "disabled",
      },
      {
        label: "Name",
        field: "name",
        width: 200,
      },
      {
        label: "Email",
        field: "email",
        width: 250,
      },
      {
        label: "Mobile",
        field: "mobile",
        width: 150,
      },
      {
        label: "Is Active",
        field: "is_closed",
        width: 150,
      },
      {
        label: "Course Name",
        field: "course_name",
        width: 210,
      },
      {
        label: "Is Student",
        field: "is_student",
        width: 150,
      },
      {
        label: "Institute",
        field: "institute",
        width: 250,
      },
      {
        label: "Course",
        field: "course",
        width: 210,
      },
      {
        label: "Date",
        field: "date",
        width: 200,
      },
      {
        label: "Message",
        field: "msg",
        width: 200,
      },
      {
        label: "Remark",
        field: "remark",
        width: 200,
      },

      {
        label: "Followup Data",
        field: "next_followup_date",
        width: 180,
      },
      {
        label: "Action",
        field: "action",
        width: 100,
      },
    ],
    rows: [],
  });

  const onSubmitHandler = () => {
    let [isExist, field] = requiredFieldValidation(values, [
      "course_name",
      "course",
      "is_closed",
      "institute",
    ]);
    if (!isExist) {
      AlertMessage(`Field - ${field}`, "error", "This field required");
      return;
    }

    let newValues = { ...values };
    if (values.course?.value) {
      newValues = { ...newValues, course: values.course?.value };
    }

    if (isEditing) {
      dispatch(updateEnquireDataAction(newValues, currentData.id));
    } else dispatch(addEnquireDataAction(newValues));
  };

  const onEnquiryStatusChange = (status, _id) => {
    dispatch(updateEnquireDataAction({ is_closed: status }, _id));
    setTimeout(
      () => dispatch(getEnquireDataAction({ is_closed: enqueryStatus })),
      1000
    );
  };

  useEffect(() => {
    if (isApiSuccess) {
      setIsAdding(false);
      setIsEditing(false);
      setValues(defaultValues);
    }
  }, [isApiSuccess]);

  useEffect(() => {
    let allRows = [];
    fetchedData?.map((data, index) => {
      let oneRow = {
        sn: index + 1,
        name: data.name,
        email: data.email,
        mobile: data.mobile,
        institute: data.institute,
        msg: data.message,
        remark: data.remark,
        course_name: data?.course_name,
        course: data.course?.name,
        date: formateDate(data.date),
        next_followup_date: formateDate(data.next_followup_date),
        action: (
          <span>
            <i
              onClick={() => {
                onEditClick(data);
              }}
              className="simple-icon-pencil cursor-pointer c_ed7117 mr-4px"
            />
            <i
              onClick={() => {
                setIsDeleting(true);
                setCurrentData(data);
              }}
              className="simple-icon-trash cursor-pointer c_ed7117 mr-4px"
            />
          </span>
        ),
        is_closed: (
          <Switch
            className="custom-switch custom-switch-3-inverse custom-switch-small"
            checked={!data.is_closed}
            onChange={(status) => onEnquiryStatusChange(!status, data.id)}
          />
        ),
        is_student: (
          <Switch
            className="custom-switch custom-switch-3-inverse custom-switch-small"
            checked={data.is_student}
            disabled
          />
        ),
      };
      allRows.push(oneRow);
    });

    setDatatable({ ...datatable, rows: allRows });
  }, [fetchedData, courseSelectOption]);

  useEffect(() => {
    dispatch(getAllCourseDataAction());
    dispatch(getEnquireDataAction({ is_closed: enqueryStatus }));
  }, []);

  const onDeleteClick = (_id) => {
    dispatch(deleteEnquireDataAction(_id));
    setCurrentData(null);
    setIsDeleting(false);
    setTimeout(
      () => dispatch(getEnquireDataAction({ is_closed: enqueryStatus })),
      1000
    );
  };

  const onEditClick = (data) => {
    let valuesObj = {
      name: data.name,
      email: data.email,
      mobile: data.mobile,
      institute: data.institute,
      message: data.message,
      remark: data.remark,
      is_closed: data.is_closed,
      course_name: data.course_name,
      date: data.date,
      next_followup_date: data.next_followup_date,
      course: courseSelectOption.find((x) => x.value === data.course?.id),
    };

    setValues(valuesObj);
    setIsEditing(true);
    setCurrentData(data);
  };

  props = {
    ...props,
    isDeleting,
    setIsDeleting,
    currentData,
    setCurrentData,
    onDeleteClick,
  };

  return (
    <React.Fragment>
      {isDeleting && <DeleteConfirmationPopUp {...props} />}
      <Row>
        <Colxx xxs="12">
          <div className="table-header-container">
            <span className="table-header-title">
              List of Enquires
              <p className="fs-14px c_black-60p">
                Here we have a list of Enquires, which is coming on the website.
              </p>
            </span>
            <div className="d-flex align-items-center">
              <i
                onClick={() => {
                  setEnqueryStatus(!enqueryStatus);
                  dispatch(getEnquireDataAction({ is_closed: !enqueryStatus }));
                }}
                className={`${
                  !enqueryStatus
                    ? "simple-icon-close "
                    : "iconsminds-radioactive"
                } cursor-pointer fs-32px mr-10px`}
              />
              <button
                onClick={() => {
                  setIsAdding(true);
                }}
                className="shadow-box create-new-button"
              >
                Add New Enquire
              </button>
            </div>
          </div>
          <Separator className="mb-5" />
        </Colxx>
      </Row>
      <Row>
        {!isAdding && !isEditing && (
          <Colxx lg="12" xl="12">
            <Card className="d-flex mb-3 br-20px" key={`faqItem_1`}>
              <div className="d-flex flex-grow-1 min-width-zero">
                <div
                  className="card-body accordion-content pt-0"
                  style={{ maxWidth: "100%" }}
                >
                  <MDBDataTable
                    fixed
                    hover
                    entriesOptions={[25, 50, 100, 500, 1000]}
                    entries={25}
                    pagesAmount={4}
                    data={datatable}
                    materialSearch
                    // searchTop
                    fullPagination
                    striped
                    theadColor="dark"
                    searching={false}
                    pagingTop
                    scrollX={true}
                    // scrollY
                    maxHeight={1000}
                    searchBottom={false}
                    // barReverse={true}
                  />
                </div>
              </div>
            </Card>
          </Colxx>
        )}
        {(isAdding || isEditing) && (
          <Colxx lg="12" xl="12">
            <Card className="d-flex mb-3 br-20px" key={`faqItem_1`}>
              <div className="d-flex flex-grow-1 min-width-zero">
                <div className="card-body accordion-content pt-0">
                  <Row className="mt-30px">
                    <Colxx lg="12" xl="4">
                      <label className="input-label fw-400 fs-14px">
                        Select Course
                      </label>
                      <Select
                        isClearable
                        options={courseSelectOption}
                        className="react-select"
                        classNamePrefix="react-select"
                        value={values.course}
                        onChange={(data) =>
                          setValues({ ...values, course: data })
                        }
                      />
                    </Colxx>

                    <Colxx lg="12" xl="4" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Any Other Course
                      </label>
                      <input
                        type={"text"}
                        className="form-control br-10px"
                        placeholder="ex. AR - VR"
                        value={values.course_name}
                        onChange={(data) =>
                          setValues({
                            ...values,
                            course_name: data.target.value,
                          })
                        }
                      />
                    </Colxx>

                    <Colxx lg="12" xl="4" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Institute Name
                      </label>
                      <input
                        type={"text"}
                        className="form-control br-10px"
                        placeholder="TechSimPlus"
                        value={values.institute}
                        onChange={(data) =>
                          setValues({
                            ...values,
                            institute: data.target.value,
                          })
                        }
                      />
                    </Colxx>

                    <Colxx lg="12" xl="4" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">Name</label>
                      <input
                        type={"text"}
                        className="form-control br-10px"
                        placeholder="Prateek Mishra"
                        value={values.name}
                        onChange={(data) =>
                          setValues({
                            ...values,
                            name: data.target.value,
                          })
                        }
                      />
                    </Colxx>

                    <Colxx lg="12" xl="4" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Email
                      </label>
                      <input
                        type={"text"}
                        className="form-control br-10px"
                        placeholder="ex. training@techsimplus.com"
                        value={values.email}
                        onChange={(data) =>
                          setValues({
                            ...values,
                            email: data.target.value,
                          })
                        }
                      />
                    </Colxx>

                    <Colxx lg="12" xl="4" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Mobile Number
                      </label>
                      <input
                        type={"text"}
                        className="form-control br-10px"
                        placeholder="ex. +91 9893762256"
                        value={values.mobile}
                        onChange={(data) =>
                          setValues({
                            ...values,
                            mobile: data.target.value,
                          })
                        }
                      />
                    </Colxx>

                    <Colxx lg="12" xl="12" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Description
                      </label>
                      <textarea
                        className="form-control br-10px"
                        placeholder="ex. About you..."
                        value={values.message}
                        onChange={(data) =>
                          setValues({
                            ...values,
                            message: data.target.value,
                          })
                        }
                      />
                    </Colxx>
                    <hr />
                    <Colxx lg="12" xl="4" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Remark
                      </label>
                      <input
                        type={"text"}
                        className="form-control br-10px"
                        placeholder="ex. student want to join next batch"
                        value={values.remark}
                        onChange={(data) =>
                          setValues({
                            ...values,
                            remark: data.target.value,
                          })
                        }
                      />
                    </Colxx>

                    <Colxx lg="12" xl="4" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Next Followup Date
                      </label>
                      <DatePicker
                        selected={
                          values.next_followup_date
                            ? new Date(values.next_followup_date)
                            : null
                        }
                        dateFormat="dd-MMM-yy"
                        onChange={(d) => {
                          setValues({ ...values, next_followup_date: d });
                          console.log(d);
                        }}
                      />
                    </Colxx>

                    <Colxx lg="12" xl="4" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        {!values.is_closed ? "Close Enquire" : "Re-Open Again"}
                      </label>
                      <Switch
                        className="custom-switch custom-switch-3-inverse custom-switch"
                        checked={!values.is_closed}
                        onChange={(status) =>
                          setValues({ ...values, is_closed: !status })
                        }
                      />
                    </Colxx>

                    <div className="d-flex justify-content-flex-end w-100percent">
                      <Button
                        onClick={() => {
                          setIsAdding(false);
                          setIsEditing(false);
                          setCurrentData(null);
                          setValues(defaultValues);
                        }}
                        size={"sm"}
                        color="dark"
                        className="br-20px c_white shadow-box create-new-button mt-30px"
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={onSubmitHandler}
                        size={"sm"}
                        color="secondry"
                        className="br-20px c_white shadow-box create-new-button mt-30px"
                      >
                        Create
                      </Button>
                    </div>
                  </Row>
                </div>
              </div>
            </Card>
          </Colxx>
        )}
      </Row>
    </React.Fragment>
  );
};

export default Enquires;
