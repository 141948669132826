import {
  defaultDirection,
  defaultLocale,
  defaultColor,
  localeOptions,
  themeColorStorageKey,
  themeRadiusStorageKey,
} from "../constants/defaultValues";

export const mapOrder = (array, order, key) => {
  // eslint-disable-next-line func-names
  array.sort(function (a, b) {
    const A = a[key];
    const B = b[key];
    if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
      return 1;
    }
    return -1;
  });
  return array;
};

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // January is 0!

  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${dd}.${mm}.${yyyy}`;
};

export const getCurrentTime = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}`;
};

export const getDirection = () => {
  let direction = defaultDirection;

  try {
    if (localStorage.getItem("direction")) {
      const localValue = localStorage.getItem("direction");
      if (localValue === "rtl" || localValue === "ltr") {
        direction = localValue;
      }
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : getDirection -> error", error);
    direction = defaultDirection;
  }
  return {
    direction,
    isRtl: direction === "rtl",
  };
};
export const setDirection = (localValue) => {
  let direction = "ltr";
  if (localValue === "rtl" || localValue === "ltr") {
    direction = localValue;
  }
  try {
    localStorage.setItem("direction", direction);
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : setDirection -> error", error);
  }
};

export const getCurrentColor = () => {
  let currentColor = defaultColor;
  try {
    if (localStorage.getItem(themeColorStorageKey)) {
      currentColor = localStorage.getItem(themeColorStorageKey);
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : getCurrentColor -> error", error);
    currentColor = defaultColor;
  }
  return currentColor;
};

export const setCurrentColor = (color) => {
  try {
    localStorage.setItem(themeColorStorageKey, color);
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : setCurrentColor -> error", error);
  }
};

export const getCurrentRadius = () => {
  let currentRadius = "rounded";
  try {
    if (localStorage.getItem(themeRadiusStorageKey)) {
      currentRadius = localStorage.getItem(themeRadiusStorageKey);
    }
  } catch (error) {
    console.log(
      ">>>>: src/helpers/Utils.js : getCurrentRadius -> error",
      error
    );
    currentRadius = "rounded";
  }
  return currentRadius;
};
export const setCurrentRadius = (radius) => {
  try {
    localStorage.setItem(themeRadiusStorageKey, radius);
  } catch (error) {
    console.log(
      ">>>>: src/helpers/Utils.js : setCurrentRadius -> error",
      error
    );
  }
};

export const getCurrentLanguage = () => {
  let language = defaultLocale;
  try {
    language =
      localStorage.getItem("currentLanguage") &&
      localeOptions.filter(
        (x) => x.id === localStorage.getItem("currentLanguage")
      ).length > 0
        ? localStorage.getItem("currentLanguage")
        : defaultLocale;
  } catch (error) {
    console.log(
      ">>>>: src/helpers/Utils.js : getCurrentLanguage -> error",
      error
    );
    language = defaultLocale;
  }
  return language;
};
export const setCurrentLanguage = (locale) => {
  try {
    localStorage.setItem("currentLanguage", locale);
  } catch (error) {
    console.log(
      ">>>>: src/helpers/Utils.js : setCurrentLanguage -> error",
      error
    );
  }
};

export const getCurrentUser = () => {
  let user = null;
  try {
    user =
      localStorage.getItem("gogo_current_user") != null
        ? JSON.parse(localStorage.getItem("gogo_current_user"))
        : null;
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js  : getCurrentUser -> error", error);
    user = null;
  }
  return user;
};

export const setCurrentUser = (user) => {
  try {
    if (user) {
      localStorage.setItem("gogo_current_user", JSON.stringify(user));
    } else {
      localStorage.removeItem("gogo_current_user");
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : setCurrentUser -> error", error);
  }
};

export const formateDate = (dt) => {
  const date = new Date(dt);
  const formattedDate = date
    .toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "2-digit",
    })
    .replace(/ /g, "-");
  return formattedDate;
};

export const getSubDomain = (Url) => {
  Url = Url.split("//")[1];
  Url = Url.split(".");
  Url = Url[0] === "www" ? Url[1] : Url[0];

  // console.log(Url.indexOf("localhost") !== -1, Url);
  return [Url.indexOf(process.env.REACT_APP_HOST) === -1, Url];
};

export const requiredFieldValidation = (obj, excludes = []) => {
  let allFieldExist = true;
  let fieldName = "";
  Object.keys(obj).map((key) => {
    if (
      (!obj[key] && obj[key] !== 0) ||
      obj[key] === "" ||
      obj[key] === null ||
      obj[key] === undefined ||
      obj[key] === []
    ) {
      if (!excludes.includes(key) && allFieldExist) {
        allFieldExist = false;
        fieldName = key;
      }
    }
  });

  return [allFieldExist, fieldName];
};

export const checkIfEmail = (str) => {
  // Regular expression to check if string is email
  const regexExp =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi;

  return regexExp.test(str);
};

export const selectCustomStyles = {
  valueContainer: (provided, state) => ({
    ...provided,
    minHeight: "42px",
    padding: "0 20px",
  }),

  input: (provided, state) => ({
    ...provided,
    margin: "0px",
  }),

  indicatorsContainer: (provided, state) => ({
    ...provided,
    minHeight: "35px",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    // display: "none",
    minHeight: "35px",
    // padding: "8px 8px 0px 0px",
  }),
  indicatorSeparator: () => {},

  control: (styles, data) => {
    const { isFocused, menuIsOpen } = data;
    return {
      ...styles,
      backgroundColor: "white",
      minHeight: "35px",
      boxShadow: menuIsOpen
        ? "0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);"
        : isFocused
        ? "white 0px 0px 0px 1px"
        : null,
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      borderBottomLeftRadius: menuIsOpen ? 0 : 20,
      borderBottomRightRadius: menuIsOpen ? 0 : 20,
      border: "none",
    };
  },
  menu: (styles, data) => {
    const { isFocused, menuIsOpen } = data;
    return {
      ...styles,
      marginTop: -3,
      border: "none",
      borderTopColor: "rgba(0, 0, 0, 0.12)",
      borderBottomLeftRadius: menuIsOpen ? 40 : null,
      borderBottomRightRadius: menuIsOpen ? 10 : null,
      boxShadow:
        "0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0);",
      zIndex: 2,
    };
  },
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      height: "40px",
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? "#BEE1E6"
        : isFocused
        ? "#BEE1E6"
        : null,
      border: "none",
      color: "black",
      cursor: isDisabled ? "not-allowed" : "default",
      ":active": {
        ...styles[":active"],
        backgroundColor: "#BEE1E6",
      },
    };
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      borderRadius: "4px",
      backgroundColor: "gray",
    };
  },

  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: "#000000",
  }),

  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: "#000000",
    ":hover": {
      cursor: "pointer",
    },
  }),
};

const arrRange = (start, stop, step) => {
  let arr = Array.from(
    { length: (stop - start) / step + 1 },
    (_, i) => start + i * step
  );

  let selectOptions = [];
  arr.map((x) => selectOptions.push({ label: x, value: x }));

  return { selectOptions, arr };
};

export { arrRange };
