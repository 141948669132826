import React, { useState, useEffect } from "react";
import { Button, Card, Row } from "reactstrap";
import Select from "react-select";
import { MDBDataTable } from "mdbreact";
import { useSelector, useDispatch } from "react-redux";
import { perPageOptions } from "../../constants/selectOptions";
import Pagination from "./pagination/Pagination";

const CustomeTable = (props) => {
  const { datatable = {}, total, onPageChange = (e) => {} } = props;

  const [perPage, setPerPage] = useState({ label: 5, value: 5 });
  const [currentPage, setCurrentPage] = useState({ label: 1, value: 1 });

  useEffect(() => {
    onPageChange({ perPage: perPage, page_number: currentPage });
  }, [perPage, currentPage]);

  return (
    <div>
      <div className="d-flex align-items-center mb-5px mt-10px">
        <p className="mt-20px mr-10px">Show entries</p>
        <Select
          value={perPage}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          onChange={(e) => {
            setPerPage(e);
            setCurrentPage({ label: 1, value: 1 });
          }}
          className="w-50px"
          placeholder="."
          options={perPageOptions}
        />
      </div>
      <MDBDataTable
        fixed
        hover
        entriesOptions={[10, 25, 50, 100, 500, 1000]}
        entries={10}
        pagesAmount={4}
        data={datatable}
        materialSearch
        // searchTop
        fullPagination
        striped
        theadColor="dark"
        searching={false}
        pagingTop
        paging={false}
        scrollX={true}
        maxHeight={500}
        searchBottom={false}
      />
      <div className="d-flex align-items-center justify-content-spacebetween mt-10px">
        <p className="mr-10px mt-6px">Total {total} entries</p>
        <Pagination
          currentPage={currentPage.value}
          totalCount={total ? total : 1}
          pageSize={perPage.value}
          onPageChange={(e) => {
            setCurrentPage({ label: e, value: e });
          }}
        />
      </div>
    </div>
  );
};

export default CustomeTable;
