import React, { useEffect, useState } from "react";
import { getOneCertificatesAPI } from "../../../../../services/master";
import parse from "html-react-parser";
const queryString = require("query-string");

const StudentCertificate = (props) => {
  let { student_id, enrollment_id } = queryString.parse(props.location.search);
  const [certificate, setCertificate] = useState(null);
  const getOneCertificate = () => {
    getOneCertificatesAPI({
      student_id: student_id,
      enrollment_id: enrollment_id,
    }).then((res) => {
      setCertificate(res.data.templete);
    });
  };

  function printCertificate() {
    var divContents = document.getElementById("certificate").innerHTML;
    var a = window.open("", "", "height=500, width=500");
    a.document.write("<html>");
    a.document.write("<body >");
    a.document.write(divContents);
    a.document.write("</body></html>");
    a.document.close();
    a.print();
  }

  useEffect(() => {
    if (student_id && enrollment_id) {
      getOneCertificate();
    }
  }, [student_id, enrollment_id]);

  return (
    <React.Fragment>
      <div id="certificate">{certificate && parse(certificate)}</div>
      <button onClick={printCertificate}>Print</button>
    </React.Fragment>
  );
};

export default StudentCertificate;
