import React from "react";
import {
  Card,
  CardBody,
  CardTitle,
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";

import { LineChart } from "../../components/charts";

import { ThemeColors } from "../../helpers/ThemeColors";

const colors = ThemeColors();

function Min(input) {
  if (toString.call(input) !== "[object Array]") return false;
  return Math.min.apply(null, input);
}

function randomNumber(min, max) {
  const r = Math.random() * (max - min) + min;
  return Math.floor(r);
}

const SalesChartCard = ({ data }) => {
  const totalRevenueData = [
    2154, 2654, 1211, 669, 3127, 1652, 3689, 465, 1143, 1785, 620, 3782, 1804,
    3918, 3444, 2462, 2774, 2854, 728, 4192, 4230, 2893, 900, 4366, 3883, 3929,
    3673, 3989, 3062, 4897,
  ];
  const totalEntryCountData = [];
  if (data) {
    Object.keys(data).map((key) => {
      // totalRevenueData.push(data[key].total_revenue);
      // totalRevenueData.push(randomNumber(400, 5000));
      totalEntryCountData.push(data[key].total_entry);
    });
  }

  console.log(totalRevenueData);

  let minValue = Math.min.apply(null, totalRevenueData);
  let maxValue = Math.max.apply(null, totalRevenueData);
  maxValue = maxValue === minValue ? maxValue + 10 : maxValue;

  const lineChartData = {
    labels: data ? Object.keys(data).reverse() : [],
    datasets: [
      {
        label: "",
        data: totalRevenueData.length > 0 ? totalRevenueData.reverse() : [0],
        borderColor: colors.themeColor1,
        pointBackgroundColor: colors.foregroundColor,
        pointBorderColor: colors.themeColor1,
        pointHoverBackgroundColor: colors.themeColor1,
        pointHoverBorderColor: colors.foregroundColor,
        pointRadius: 6,
        pointBorderWidth: 2,
        pointHoverRadius: 8,
        fill: false,
      },
    ],
  };

  return (
    <Card>
      <div className="position-absolute card-top-buttons">
        <UncontrolledDropdown>
          <DropdownToggle color="" className="btn btn-header-light icon-button">
            <i className="simple-icon-refresh" />
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem>Sales</DropdownItem>
            <DropdownItem>Orders</DropdownItem>
            <DropdownItem>Refunds</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
      <CardBody>
        <CardTitle>Daily Generated Revanue Graph</CardTitle>
        <div className="dashboard-line-chart">
          <LineChart
            shadow
            minValue={minValue}
            maxValue={maxValue}
            data={lineChartData}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default SalesChartCard;
