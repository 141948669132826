/* eslint-disable react/no-array-index-key */
import React from "react";
import IconCard from "../../components/cards/IconCard";

import GlideComponent from "../../components/carousel/GlideComponent";

const IconCardsCarousel = ({
  className = "icon-cards-row",
  perView = 5,
  cardData = null,
  values = null,
}) => {
  let data = [
    {
      title: "Total Courses",
      icon: "simple-icon-book-open",
      value: values ? values.courses : 0,
    },

    {
      title: "Total Batches",
      icon: "simple-icon-calendar",
      value: values ? values.batches : 0,
    },
    {
      title: "Number of Blogs",
      icon: "iconsminds-blogger",
      value: values ? values.blogs : 0,
    },
    {
      title: "Enquires",
      icon: "simple-icon-call-in",
      value: values ? values.total_enquires : 0,
    },
    {
      title: "Total Students",
      icon: "iconsminds-students",
      value: values ? values.total_students : 0,
    },
    {
      title: "Unpaid Students",
      icon: "iconsminds-qr-code",
      value: values ? values.unpaid_students : 0,
    },
  ];

  return (
    <div className={className}>
      <GlideComponent
        settings={{
          gap: 5,
          perView: perView,
          type: "carousel",
          breakpoints: {
            335: { perView: perView > 1 ? 1 : perView },
            476: { perView: perView > 2 ? 2 : perView },
            700: { perView: perView > 3 ? 3 : perView },
            900: { perView: perView > 4 ? 4 : perView },
            1200: { perView: perView > 5 ? 5 : perView },
            1400: { perView: perView > 6 ? 6 : perView },
          },
          hideNav: true,
        }}
      >
        {cardData
          ? cardData.map((item, index) => {
              return (
                <div key={`icon_card_${index}`}>
                  <IconCard {...item} className="mb-4" />
                </div>
              );
            })
          : data.map((item, index) => {
              return (
                <div key={`icon_card_${index}`}>
                  <IconCard {...item} className="mb-4" />
                </div>
              );
            })}
      </GlideComponent>
    </div>
  );
};
export default IconCardsCarousel;
