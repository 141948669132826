/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-use-before-define */
import React, { useState } from "react";

import {
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Input,
} from "reactstrap";

import { NavLink, useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";

import {
  setContainerClassnames,
  clickOnMobileMenu,
  logoutUser,
} from "../../redux/actions";

import {
  menuHiddenBreakpoint,
  searchPath,
  buyUrl,
  adminRoot,
} from "../../constants/defaultValues";

import { MobileMenuIcon, MenuIcon } from "../../components/svg";

const TopNav = ({
  intl,
  containerClassnames,
  menuClickCount,
  selectedMenuHasSubItems,
  setContainerClassnamesAction,
  clickOnMobileMenuAction,
}) => {
  const [isInFullScreen, setIsInFullScreen] = useState(false);

  const currentUser = useSelector((state) => state.authUser.currentUser);

  const dispatch = useDispatch();
  const history = useHistory();

  const isInFullScreenFn = () => {
    return (
      (document.fullscreenElement && document.fullscreenElement !== null) ||
      (document.webkitFullscreenElement &&
        document.webkitFullscreenElement !== null) ||
      (document.mozFullScreenElement &&
        document.mozFullScreenElement !== null) ||
      (document.msFullscreenElement && document.msFullscreenElement !== null)
    );
  };

  const toggleFullScreen = () => {
    const isFS = isInFullScreenFn();

    const docElm = document.documentElement;
    if (!isFS) {
      if (docElm.requestFullscreen) {
        docElm.requestFullscreen();
      } else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen();
      } else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen();
      } else if (docElm.msRequestFullscreen) {
        docElm.msRequestFullscreen();
      }
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
    setIsInFullScreen(!isFS);
  };

  const menuButtonClick = (e, _clickCount, _conClassnames) => {
    e.preventDefault();

    setTimeout(() => {
      const event = document.createEvent("HTMLEvents");
      event.initEvent("resize", false, false);
      window.dispatchEvent(event);
    }, 350);
    setContainerClassnamesAction(
      _clickCount + 1,
      _conClassnames,
      selectedMenuHasSubItems
    );
  };

  const mobileMenuButtonClick = (e, _containerClassnames) => {
    e.preventDefault();
    clickOnMobileMenuAction(_containerClassnames);
  };

  return (
    <nav className="navbar fixed-top">
      <div className="display-flex align-items-center navbar-left mt-n5px">
        <div className="mt-n15px mr-15px">
          <NavLink
            to="#"
            location={{}}
            className="menu-button d-none d-md-block"
            onClick={(e) =>
              menuButtonClick(e, menuClickCount, containerClassnames)
            }
          >
            <MenuIcon />
          </NavLink>
          <NavLink
            to="#"
            location={{}}
            className="menu-button-mobile d-xs-block d-sm-block d-md-none"
            onClick={(e) => mobileMenuButtonClick(e, containerClassnames)}
          >
            <MobileMenuIcon />
          </NavLink>
        </div>
        <div>
          <p>
            <span className="fs-24px fw-600">TechSim+</span> <br />{" "}
            <span className="fs-16px c_black-60p">The Symbol of Expertise</span>
          </p>
        </div>
      </div>

      <NavLink className="navbar-logo" to={adminRoot}>
        <span className="logo d-none d-xs-block" />
        <span className="logo-mobile d-block d-xs-none" />
      </NavLink>

      <div className="navbar-right display-flex align-items-center justify-content-flex-end mt-n12px">
        <div className="d-none">
          <div
            className="d-flex align-items-center cursor-pointer"
            onClick={() => dispatch(logoutUser(history))}
          >
            <p>
              <i className="simple-icon-logout c_B00020" />
            </p>
            <p className="ml-10px fs-20px c_B00020">
              <u>Logout</u>
            </p>
          </div>
        </div>
        <div className="header-icons d-none align-middle mt-n10px">
          <button
            className="header-icon btn btn-empty d-none d-sm-inline-block"
            type="button"
            id="fullScreenButton"
            onClick={toggleFullScreen}
          >
            {isInFullScreen ? (
              <i className="simple-icon-size-actual d-block" />
            ) : (
              <i className="simple-icon-size-fullscreen d-block" />
            )}
          </button>
        </div>
        <div className="user d-inline-block mt-n20px">
          <UncontrolledDropdown className="dropdown-menu-right">
            <DropdownToggle className="p-0" color="empty">
              <span className="name mr-1 fw-600 c_black-60p">
                {currentUser.first_name + " " + currentUser.last_name}
              </span>
              <span
                onClick={() => {
                  localStorage.removeItem("authToken");
                  window.location.href = process.env.REACT_APP_LOGOUT_URL;
                }}
              >
                {currentUser?.institute_user?.image && (
                  <img
                    className="shadow-box"
                    alt="Profile"
                    src={currentUser?.institute_user?.image}
                  />
                )}

                {currentUser?.student?.image && (
                  <img
                    className="shadow-box"
                    alt="Profile"
                    src={currentUser?.student?.image}
                  />
                )}

                {!currentUser?.student?.image &&
                  !currentUser?.institute_user?.image && (
                    <span className="shadow-box bg-ed7117 fs-16px c_white fw-900 pl-10px pr-10px pb-10px pt-10px ml-10px br-20px">
                      {currentUser.last_name
                        ? currentUser.first_name.slice(0, 1) +
                          currentUser.last_name.slice(0, 1)
                        : ""}
                    </span>
                  )}
              </span>
            </DropdownToggle>
          </UncontrolledDropdown>
        </div>
      </div>
    </nav>
  );
};

const mapStateToProps = ({ menu, settings }) => {
  const { containerClassnames, menuClickCount, selectedMenuHasSubItems } = menu;
  return {
    containerClassnames,
    menuClickCount,
    selectedMenuHasSubItems,
  };
};
export default connect(mapStateToProps, {
  setContainerClassnamesAction: setContainerClassnames,
  clickOnMobileMenuAction: clickOnMobileMenu,
})(TopNav);
