import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import {
  getAllBatchDataAction,
  getAllCourseDataAction,
  getAllInstitutesDataAction,
  getAllStudentsDataAction,
} from "../../../../../redux/master/actions";
import { paymentStatusOptions } from "../../../../../constants/selectOptions";

const StudentFilterSection = (props) => {
  const [courseSelectOption, setCourseSelectOptions] = useState([]);
  const [courseBatchSelectOptions, setCourseBatchSelectOptions] = useState([]);
  const [instituteSelectOption, setInstituteSelectOption] = useState([]);

  const [searchParams, setSearchParams] = useState({});
  const dispatch = useDispatch();
  const allBatch = useSelector((state) => state.master.allBatch);
  const allCourses = useSelector((state) => state.master.allCourses);
  const allInstitutes = useSelector((state) => state.master.allInstitutes);

  useEffect(() => {
    dispatch(getAllCourseDataAction());
    dispatch(getAllBatchDataAction());
    dispatch(getAllInstitutesDataAction());
  }, []);

  useEffect(() => {
    let allRows = [];
    allCourses?.map((x) => allRows.push({ label: x.name, value: x.id }));
    setCourseSelectOptions(allRows);
  }, [allCourses]);

  useEffect(() => {
    let allOptions = [];
    allInstitutes.map((data, index) => {
      allOptions.push({ label: data.name, value: data.id, key: index });
    });

    setInstituteSelectOption(allOptions);
  }, [allInstitutes]);

  useEffect(() => {
    var newObj = {};
    Object.keys(searchParams).map((key) => {
      if (searchParams[key]) {
        if (searchParams[key].value.toString().length > 0)
          newObj[key] = searchParams[key].value;
      }
    });
    if (!newObj.query || newObj.query?.length > 2) {
      dispatch(getAllStudentsDataAction(newObj));
    }
  }, [searchParams]);

  return (
    <React.Fragment>
      <div className="d-flex">
        <div className="student-filter-section">
          {/* <div className="w-250px mb-10px mr-10px">
            <label className="input-label fw-400 fs-14px">
              Select Institute
            </label>
            <Select
              options={instituteSelectOption}
              isClearable
              value={searchParams.institute_id}
              onChange={(data) => {
                setSearchParams({
                  ...searchParams,
                  institute_id: data,
                  query: "",
                });
              }}
            />
          </div> */}
          <div className="w-250px mb-10px mr-10px">
            <label className="input-label fw-400 fs-14px">Select Course</label>
            <Select
              options={courseSelectOption}
              isClearable
              value={searchParams.course_id}
              onChange={(data) => {
                setSearchParams({
                  ...searchParams,
                  course_id: data,
                  batch_id: null,
                  query: "",
                });
                let allOpt = [];
                allBatch.map((x) => {
                  if (x.course.id === data?.value) {
                    allOpt.push({ label: x.name, value: x.id });
                  }
                });
                setCourseBatchSelectOptions(allOpt);
              }}
            />
          </div>

          <div className="w-250px mb-10px mr-10px">
            <label className="input-label fw-400 fs-14px">Select Batch</label>
            <Select
              options={courseBatchSelectOptions}
              isClearable
              value={searchParams.batch_id}
              onChange={(data) => {
                setSearchParams({
                  ...searchParams,
                  batch_id: data,
                  query: "",
                });
              }}
            />
          </div>
          <div className="w-250px mb-10px mr-10px">
            <label className="input-label fw-400 fs-14px">Fee Status</label>
            <Select
              options={paymentStatusOptions}
              isClearable
              value={searchParams.status}
              onChange={(data) => {
                setSearchParams({
                  ...searchParams,
                  status: data,
                  query: "",
                });
              }}
            />
          </div>

          <div className="w-250px mb-10px mr-10px">
            <label className="input-label fw-400 fs-14px">Other Options</label>
            <Select
              options={[
                { label: "Is Blogger", value: "is_blogger" },
                { label: "Not Enrolled", value: "is_unenrolled" },
              ]}
              isClearable
              value={searchParams.other}
              onChange={(data) => {
                setSearchParams({
                  ...searchParams,
                  other: data,
                  query: "",
                });
              }}
            />
          </div>

          <div className="w-200px mb-10px mr-10px">
            <label className="input-label fw-400 fs-14px">Search</label>
            <input
              type={"text"}
              className="form-control br-10px"
              placeholder="Search..."
              value={searchParams.query ? searchParams.query?.value : ""}
              onChange={(data) =>
                setSearchParams({
                  ...searchParams,
                  query: { value: data.target.value },
                })
              }
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default StudentFilterSection;
