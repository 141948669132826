import React, { useState, useEffect } from "react";
import {
  Row,
  Card,
  CardBody,
  Nav,
  NavItem,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  TabContent,
  TabPane,
  Badge,
} from "reactstrap";
import {
  Colxx,
  Separator,
} from "../../../../../../components/common/CustomBootstrap";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { courseLabelOptions } from "../../../../../../constants/selectOptions";
import {
  deleteCourseAPI,
  getAllCoursesAPI,
} from "../../../../../../services/master";
import AlertMessage from "../../../../../../helpers/alerts";
import { MDBDataTable } from "mdbreact";
import {
  getAllCourseDataAction,
  getOneCourseDataAction,
} from "../../../../../../redux/master/actions";
import DeleteConfirmationPopUp from "../../../../../../components/common/deleteConfirmationPopup";

const queryString = require("query-string");

const CourseListSection = (props) => {
  const allCourses = useSelector((state) => state.master.allCourses);
  const { setIsAdding, setIsEditing } = props;
  const [isDeleting, setIsDeleting] = useState(false);
  const [currentData, setCurrentData] = useState(null);

  const dispatch = useDispatch();

  const [datatable, setDatatable] = React.useState({
    columns: [
      {
        label: "#",
        field: "sn",
        width: 50,
        attributes: {
          "aria-controls": "DataTable",
          "aria-label": "id",
        },
        sort: "disabled",
      },
      {
        label: "Label",
        field: "label",
        width: 180,
      },
      {
        label: "Name",
        field: "name",
        width: 200,
      },
      {
        label: "Duration",
        field: "duration",
        width: 120,
      },

      {
        label: "Fee (Rs.)",
        field: "actual_fee",
        width: 120,
      },

      {
        label: "Discounted Fee (Rs.)",
        field: "discounted_fee",
        width: 180,
      },
      {
        label: "Fee ($)",
        field: "actual_fee_doller",
        width: 120,
      },

      {
        label: "Discounted Fee ($)",
        field: "discounted_fee_doller",
        width: 200,
      },
      {
        label: "Trainers Count",
        field: "total_trainers",
        width: 160,
      },

      {
        label: "Technologies Count",
        field: "total_technologies",
        width: 200,
      },

      {
        label: "Order",
        field: "order",
        width: 120,
      },
      {
        label: "Action",
        field: "action",
        width: 100,
      },
    ],
    rows: [],
  });

  useEffect(() => {
    let allRows = [];
    allCourses.map((course, index) => {
      let oneRow = {
        sn: index + 1,
        label: course.label,
        name: course.name,
        duration: course.duration,
        actual_fee: course.actual_fee,
        discounted_fee: course.discounted_fee,
        actual_fee_doller: course.actual_fee_doller,
        discounted_fee_doller: course.discounted_fee_doller,
        order: course.order,
        total_trainers: course.trainers ? course.trainers.length : 0,
        total_technologies: course.technologies
          ? course.technologies.length
          : 0,
        action: (
          <span>
            <i
              onClick={() => {
                setIsEditing(true);
                setIsAdding(false);
                dispatch(getOneCourseDataAction(course.id));
                props.history.push(
                  `${props.history.location.pathname}?course_id=${course.id}&action=edit`
                );
              }}
              className="simple-icon-pencil cursor-pointer c_ed7117 mr-4px"
            />
            <i
              onClick={() => {
                setIsDeleting(true);
                setCurrentData(course);
              }}
              className="simple-icon-trash cursor-pointer c_ed7117 mr-4px"
            />
          </span>
        ),
      };
      allRows.push(oneRow);
    });

    setDatatable({ ...datatable, rows: allRows });
  }, [allCourses]);

  useEffect(() => {
    dispatch(getAllCourseDataAction());
  }, []);

  const onDeleteClick = (_id) => {
    deleteCourseAPI({ _id: _id }).then(
      (res) => {
        setCurrentData(null);
        setIsDeleting(false);
        dispatch(getAllCourseDataAction());
      },
      (error) => {
        AlertMessage("Internal Server Error");
      }
    );
  };

  props = {
    ...props,
    isDeleting,
    setIsDeleting,
    currentData,
    setCurrentData,
    onDeleteClick,
  };

  return (
    <React.Fragment>
      {isDeleting && <DeleteConfirmationPopUp {...props} />}
      <Row>
        <Colxx lg="12" xl="12">
          <Card className="d-flex mb-3 br-20px" key={`faqItem_1`}>
            <div className="d-flex flex-grow-1 min-width-zero">
              <div
                className="card-body accordion-content pt-0"
                style={{ maxWidth: "100%" }}
              >
                <MDBDataTable
                  fixed
                  hover
                  entriesOptions={[25, 50, 100, 500, 1000]}
                  entries={25}
                  pagesAmount={4}
                  data={datatable}
                  materialSearch
                  // searchTop
                  fullPagination
                  striped
                  theadColor="dark"
                  searching={false}
                  pagingTop
                  scrollX={true}
                  // scrollY
                  maxHeight={1000}
                  searchBottom={false}
                  // barReverse={true}
                />
              </div>
            </div>
          </Card>
        </Colxx>
      </Row>
    </React.Fragment>
  );
};

export default CourseListSection;
