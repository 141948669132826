import React, { useState, useEffect } from "react";
import { Button, Card, Row } from "reactstrap";
import filterIcon from "../../../../assets/img/icons/solid/filter.svg";
import {
  Colxx,
  Separator,
} from "../../../../components/common/CustomBootstrap";
import { MDBDataTable } from "mdbreact";
import { useSelector, useDispatch } from "react-redux";
import Switch from "rc-switch";

import {
  deleteStudentDataAction,
  deleteUserDataAction,
  errorHandler,
  getAllBatchDataAction,
  getAllCourseDataAction,
  getAllInstitutesDataAction,
  getAllStudentsDataAction,
  getAllUsersDataAction,
  getTeamMemberDataAction,
} from "../../../../redux/master/actions";
import Select from "react-select";
import AlertMessage from "../../../../helpers/alerts";
import DeleteConfirmationPopUp from "../../../../components/common/deleteConfirmationPopup";
import { updateStudentsAPI } from "../../../../services/master";

import { selectCustomStyles } from "../../../../helpers/Utils";
import StudentFilterSection from "./components/studentsFilter";
import AddEditSection from "./components/addEdit";
import OneStudent from "./components/oneStudent";
import StudentCertificate from "./components/certificate";
const queryString = require("query-string");

const StudentsData = (props) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [toggleFilter, setToggleFilter] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  let { student_id, action, enrollment_id } = queryString.parse(
    props.location.search
  );

  const dispatch = useDispatch();
  const fetchedData = useSelector((state) => state.master.allStudents);

  const [datatable, setDatatable] = React.useState({
    columns: [
      {
        label: "#",
        field: "sn",
        width: 50,
        attributes: {
          "aria-controls": "DataTable",
          "aria-label": "id",
        },
        sort: "disabled",
      },

      {
        label: "Name",
        field: "name",
        width: 200,
      },
      {
        label: "Mobile",
        field: "mobile",
        width: 150,
      },

      {
        label: "Email",
        field: "email",
        width: 250,
      },

      {
        label: "Who You Are ?",
        field: "category",
        width: 200,
      },
      {
        label: "Institute Name",
        field: "institute_name",
        width: 280,
      },
      {
        label: "Passing Year",
        field: "year",
        width: 180,
      },

      {
        label: "Referal Code",
        field: "referal_code",
        width: 120,
        sort: "disabled",
      },
      {
        label: "Is Blogger",
        field: "isBlogger",
        width: 120,
        sort: "disabled",
      },
      {
        label: "Date",
        field: "date",
        width: 200,
      },

      {
        label: "Action",
        field: "action",
        width: 100,
        sort: "disabled",
      },
    ],
    rows: [],
  });

  const [bloggerRequestdatatable, setBloggerRequestDatatable] = React.useState({
    columns: [
      {
        label: "#",
        field: "sn",
        width: 50,
        attributes: {
          "aria-controls": "DataTable",
          "aria-label": "id",
        },
        sort: "disabled",
      },

      {
        label: "Name",
        field: "name",
        width: 200,
      },
      {
        label: "Mobile",
        field: "mobile",
        width: 150,
      },

      {
        label: "Email",
        field: "email",
        width: 250,
      },

      {
        label: "Who You Are ?",
        field: "category",
        width: 200,
      },
      {
        label: "Institute Name",
        field: "institute_name",
        width: 280,
      },

      {
        label: "Take Action",
        field: "take_action",
        width: 120,
        sort: "disabled",
      },
    ],
    rows: [],
  });

  useEffect(() => {
    let allRows = [];
    let bloggerRequests = [];
    fetchedData?.map((data, index) => {
      let oneRow = {
        sn: index + 1,
        name: data.name,
        mobile: data.mobile,
        email: data.email,
        category: data.category,
        institute_name: data.institute_name,
        year: data.year,
        referal_code: data.referal_code,
        isBlogger: (
          <Switch
            className="custom-switch custom-switch-3-inverse custom-switch-small"
            checked={data.isBlogger}
            onChange={(status) => onUserStatusChange(data, "isBlogger", status)}
          />
        ),
        date: new Date(data.date).toLocaleString("en-IN", {
          weekday: "short",
          year: "numeric",
          month: "short",
          day: "numeric",
        }),

        action: (
          <span className="d-flex align-items-center">
            <i
              onClick={() => {
                setIsAdding(false);
                setIsEditing(false);
                setCurrentData(null);
                props.history.push(
                  `${props.history.location.pathname}?student_id=${data.id}&action=view`
                );
              }}
              className="simple-icon-paper-plane cursor-pointer c_00508B fw-900 mr-4px"
            />
            <i
              onClick={() => {
                setCurrentData(data);
                setIsEditing(true);
                props.history.push(
                  `${props.history.location.pathname}?student_id=${data.id}&action=edit`
                );
              }}
              className="simple-icon-pencil cursor-pointer c_ed7117 mr-4px"
            />
            <i
              onClick={() => {
                setIsDeleting(true);
                setCurrentData(data);
              }}
              className="simple-icon-trash cursor-pointer c_ed7117 mr-4px"
            />
          </span>
        ),
      };
      allRows.push(oneRow);
      if (data.isBloggerRequest) {
        oneRow.take_action = (
          <Select
            styles={selectCustomStyles}
            options={[
              { label: "Accept", value: true },
              { label: "Reject", value: false },
            ]}
            className="mt-n5px"
            onChange={(status) => {
              onUserStatusChange(data, "isBlogger", status.value);
              setTimeout(() => {
                onUserStatusChange(data, "isBloggerRequest", false);
              }, 1000);
            }}
          />
        );
        bloggerRequests.push(oneRow);
      }
    });

    setDatatable({ ...datatable, rows: allRows });
    setBloggerRequestDatatable({
      ...bloggerRequestdatatable,
      rows: bloggerRequests,
    });
  }, [fetchedData]);

  useEffect(() => {
    dispatch(getAllStudentsDataAction());
  }, []);

  useEffect(() => {
    if (action === "add-new") {
      setIsAdding(true);
      setCurrentData(null);
    } else if (action === "edit" && student_id) {
      let oneStudent = fetchedData.find((x) => x.id?.toString() === student_id);
      setCurrentData(oneStudent);
      setIsEditing(true);
    } else {
      setIsAdding(false);
      setIsEditing(false);
    }
  }, [action, fetchedData]);

  const onDeleteClick = (_id) => {
    dispatch(deleteStudentDataAction(_id));
    setCurrentData(null);
    setIsDeleting(false);
  };

  const onUserStatusChange = (data, field, status) => {
    updateStudentsAPI({ [field]: status }, { _id: data.id }).then(
      (res) => {
        dispatch(getAllStudentsDataAction());
      },
      (error) => {
        dispatch(errorHandler(error));
      }
    );
  };

  props = {
    ...props,
    isDeleting,
    setIsDeleting,
    currentData,
    setCurrentData,
    onDeleteClick,
    isEditing,
    setIsEditing,
    isAdding,
    setIsAdding,
  };

  return student_id && action === "view" ? (
    <OneStudent student_id={student_id} {...props} />
  ) : student_id && action === "certificate" ? (
    <StudentCertificate {...props} />
  ) : (
    <React.Fragment>
      {isDeleting && <DeleteConfirmationPopUp {...props} />}
      <Row>
        <Colxx xxs="12">
          <div className="table-header-container">
            <span className="table-header-title">
              List of Students
              <p className="fs-14px c_black-60p">
                All students, who registered in different batches, and different
                courses.
              </p>
            </span>
            <div>
              <button
                onClick={() => {
                  setIsAdding(true);
                  props.history.push(
                    `${props.history.location.pathname}?action=add-new`
                  );
                }}
                className="shadow-box create-new-button"
              >
                Add New Student
              </button>
            </div>
          </div>
          <Separator className={toggleFilter ? `mb-2` : "mb-5"} />
        </Colxx>
      </Row>

      <Row>
        {!isAdding && !isEditing && (
          <Colxx lg="12" xl="12">
            {toggleFilter && <StudentFilterSection />}
            <Card className="d-flex mb-3 br-20px" key={`faqItem_1`}>
              <div className="position-absolute w-100percent d-flex justify-content-flex-end mt-20px pr-30px">
                <img
                  src={filterIcon}
                  alt="filter-icon"
                  onClick={() => {
                    setToggleFilter(!toggleFilter);
                  }}
                  className="w-20px filter-icon"
                />
              </div>
              <div className="d-flex flex-grow-1 min-width-zero">
                <div
                  className="card-body accordion-content pt-0"
                  style={{ maxWidth: "100%" }}
                >
                  <MDBDataTable
                    fixed
                    hover
                    entriesOptions={[25, 50, 100, 500, 1000]}
                    entries={25}
                    pagesAmount={4}
                    data={
                      bloggerRequestdatatable.rows?.length > 0
                        ? bloggerRequestdatatable
                        : datatable
                    }
                    materialSearch
                    // searchTop
                    fullPagination
                    striped
                    theadColor="dark"
                    searching={false}
                    pagingTop
                    scrollX={true}
                    // scrollY
                    maxHeight={1000}
                    searchBottom={false}
                    // barReverse={true}
                  />
                </div>
              </div>
            </Card>
          </Colxx>
        )}

        {(isAdding || isEditing) && <AddEditSection {...props} />}
      </Row>
    </React.Fragment>
  );
};

export default StudentsData;
