import React, { useState, useEffect } from "react";
import { Button, Card, Row } from "reactstrap";
import {
  Colxx,
  Separator,
} from "../../../../components/common/CustomBootstrap";
import { MDBDataTable } from "mdbreact";
import { useSelector, useDispatch } from "react-redux";
import Switch from "rc-switch";
import DatePicker from "react-datepicker";
import {
  deleteBatchDataAction,
  deleteUserDataAction,
  errorHandler,
  getAllBatchDataAction,
  getAllCourseDataAction,
  getAllInstitutesDataAction,
  getTeamMemberDataAction,
  getTempletesDataAction,
} from "../../../../redux/master/actions";
import Select from "react-select";
import AlertMessage from "../../../../helpers/alerts";
import DeleteConfirmationPopUp from "../../../../components/common/deleteConfirmationPopup";
import {
  createNewBatcheAPI,
  createNewUserAPI,
  updateBatcheAPI,
  updateUserAPI,
} from "../../../../services/master";
import {
  batchTypeOptions,
  feeByTypeOptions,
  feeCollectionTypeOptions,
  learningModeOptions,
} from "../../../../constants/selectOptions";
import { requiredFieldValidation } from "../../../../helpers/Utils";
import GenerateBulkCertificates from "./bulkCertificates";

const CourseBatches = (props) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [isCertificateGenerate, setIsCertificateGenerate] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [courseSelectOption, setCourseSelectOption] = useState([]);
  const [templeteSelectOption, setTempleteSelectOption] = useState([]);
  const [trainerSelectOption, setTrainerSelectOption] = useState([]);
  const [instituteSelectOption, setInstituteSelectOption] = useState([]);

  let defaultValues = {
    course: null,
    trainers: null,
    institute: null,
    category: null,
    learning_mode: null,
    fee_by: { label: "Student", value: "Student", key: 0 },
    fee_collection_type: { label: "Per Student", value: "Per Student", key: 0 },
    name: "",
    certificate_name: "",
    start_date: new Date(),
    end_date: new Date(),
    location: "",
    time: "", // Saperate by |
    duration: "",
    fee: "",
    fee_in_doller: "",
    certificate_prefix: "",
    certificate_templete: "",
  };

  const [values, setValues] = useState(defaultValues);

  const dispatch = useDispatch();
  const allCourses = useSelector((state) => state.master.allCourses);
  const allTrainers = useSelector((state) => state.master.teams);
  const allInstitutes = useSelector((state) => state.master.allInstitutes);
  const allTempletes = useSelector((state) => state.master.templetes);
  const fetchedData = useSelector((state) => state.master.allBatch);

  const [datatable, setDatatable] = React.useState({
    columns: [
      {
        label: "#",
        field: "sn",
        width: 50,
        attributes: {
          "aria-controls": "DataTable",
          "aria-label": "id",
        },
        sort: "disabled",
      },
      {
        label: "Course",
        field: "course",
        width: 200,
      },
      {
        label: "Name",
        field: "name",
        width: 200,
      },
      {
        label: "Trainer",
        field: "trainers",
        width: 250,
      },

      {
        label: "Paid Students",
        field: "paid_students",
        width: 150,
      },

      {
        label: "Unpaid Students",
        field: "unpaid_students",
        width: 160,
      },
      {
        label: "Start Date",
        field: "start_date",
        width: 180,
      },
      {
        label: "End Date",
        field: "end_date",
        width: 180,
      },

      {
        label: "Is Active",
        field: "is_ended",
        width: 120,
        sort: "disabled",
      },
      {
        label: "Category",
        field: "category",
        width: 120,
      },

      {
        label: "Learning Mode",
        field: "learning_mode",
        width: 150,
      },

      {
        label: "Duration",
        field: "duration",
        width: 120,
        sort: "disabled",
      },

      {
        label: "Fee (Rs)",
        field: "fee",
        width: 120,
        sort: "disabled",
      },

      {
        label: "Installment",
        field: "is_installment_allowed",
        width: 130,
      },

      {
        label: "Is Public",
        field: "is_public",
        width: 100,
      },

      {
        label: "Referal Allowed",
        field: "is_referal_active",
        width: 130,
        sort: "disabled",
      },

      {
        label: "Is Staff",
        field: "is_staff",
        width: 130,
        sort: "disabled",
      },

      {
        label: "Action",
        field: "action",
        width: 100,
        sort: "disabled",
      },
    ],
    rows: [],
  });

  const onSubmitHandler = () => {
    let [isExist, field] = requiredFieldValidation(values, [
      "certificate_templete",
      "trainers",
    ]);
    if (!isExist) {
      AlertMessage(`Field - ${field}`, "error", "This field required");
      return;
    }

    let d = new Date(values.start_date);
    var startDate =
      d.getFullYear() +
      "-" +
      ("0" + (d.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + d.getDate()).slice(-2);

    d = new Date(values.end_date);
    var endDate =
      d.getFullYear() +
      "-" +
      ("0" + (d.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + d.getDate()).slice(-2);

    let newValues = {
      ...values,
      category: values.category.value,
      course: values.course.value,
      fee_by: values.fee_by.value,
      certificate_templete: values.certificate_templete?.value,
      fee_collection_type: values.fee_collection_type.value,
      institute: values.institute.value,
      learning_mode: values.learning_mode.value,
      start_date: startDate,
      end_date: endDate,
    };

    let tIds = [];
    values.trainers.map((x) => tIds.push(x.value));
    newValues = { ...newValues, trainers: tIds };

    let finalMethod = createNewBatcheAPI;
    if (isEditing) {
      finalMethod = updateBatcheAPI;
    }

    finalMethod(newValues, { _id: isEditing ? currentData.id : 0 }).then(
      (res) => {
        setIsEditing(false);
        setIsAdding(false);
        setCurrentData(false);
        dispatch(getAllBatchDataAction());
      },
      (error) => {
        dispatch(errorHandler(error));
      }
    );
  };

  useEffect(() => {
    let allOptions = [];
    allCourses.map((data, index) => {
      allOptions.push({ label: data.name, value: data.id, key: index });
    });

    setCourseSelectOption(allOptions);
  }, [allCourses]);

  useEffect(() => {
    let allOptions = [];
    allTempletes.map((data, index) => {
      allOptions.push({ label: data.name, value: data.id, key: index });
    });

    setTempleteSelectOption(allOptions);
  }, [allTempletes]);

  useEffect(() => {
    let allOptions = [];
    allTrainers.map((data, index) => {
      allOptions.push({ label: data.name, value: data.id, key: index });
    });

    setTrainerSelectOption(allOptions);
  }, [allTrainers]);

  useEffect(() => {
    let allOptions = [];
    allInstitutes.map((data, index) => {
      allOptions.push({ label: data.name, value: data.id, key: index });
    });

    setInstituteSelectOption(allOptions);
  }, [allInstitutes]);

  useEffect(() => {
    let allRows = [];
    fetchedData?.map((data, index) => {
      let oneRow = {
        sn: index + 1,
        course: data.course.name,
        name: data.name,
        paid_students: data.paid_students,
        unpaid_students: data.unpaid_students,
        trainers: data.trainers[0]?.name,
        start_date: new Date(data.start_date).toLocaleString("en-IN", {
          weekday: "short",
          year: "numeric",
          month: "short",
          day: "numeric",
        }),

        end_date: new Date(data.end_date).toLocaleString("en-IN", {
          weekday: "short",
          year: "numeric",
          month: "short",
          day: "numeric",
        }),

        category: data.category,
        learning_mode: data.learning_mode,
        duration: data.duration,
        fee: data.fee,

        is_ended: (
          <Switch
            className="custom-switch custom-switch-2-inverse custom-switch-small"
            checked={data.is_ended ? false : true}
            onChange={(status) => onUserStatusChange(data, "is_ended", !status)}
          />
        ),
        is_installment_allowed: (
          <Switch
            className="custom-switch custom-switch-3-inverse custom-switch-small"
            checked={data.is_installment_allowed}
            onChange={(status) =>
              onUserStatusChange(data, "is_installment_allowed", status)
            }
          />
        ),
        is_public: (
          <Switch
            className="custom-switch custom-switch-3-inverse custom-switch-small"
            checked={data.is_public}
            onChange={(status) => onUserStatusChange(data, "is_public", status)}
          />
        ),
        is_referal_active: (
          <Switch
            className="custom-switch custom-switch-3-inverse custom-switch-small"
            checked={data.is_referal_active}
            onChange={(status) =>
              onUserStatusChange(data, "is_referal_active", status)
            }
          />
        ),
        is_staff: (
          <Switch
            className="custom-switch custom-switch-3-inverse custom-switch-small"
            checked={data.is_staff}
            onChange={(status) => onUserStatusChange(data, "is_staff", status)}
          />
        ),

        action: (
          <span>
            <i
              onClick={() => {
                onEditClick(data);
              }}
              className="simple-icon-pencil cursor-pointer c_ed7117 mr-4px"
            />
            <i
              onClick={() => {
                setIsDeleting(true);
                setCurrentData(data);
              }}
              className="simple-icon-trash cursor-pointer c_ed7117 mr-4px"
            />
          </span>
        ),
      };
      allRows.push(oneRow);
    });

    setDatatable({ ...datatable, rows: allRows });
  }, [
    fetchedData,
    courseSelectOption,
    trainerSelectOption,
    instituteSelectOption,
    templeteSelectOption,
  ]);

  useEffect(() => {
    dispatch(getAllCourseDataAction());
    dispatch(getAllInstitutesDataAction());
    dispatch(getAllBatchDataAction());
    dispatch(getTempletesDataAction());
    dispatch(getTeamMemberDataAction());
  }, []);

  const onDeleteClick = (_id) => {
    dispatch(deleteBatchDataAction(_id));
    setCurrentData(null);
    setIsDeleting(false);
  };

  const onUserStatusChange = (data, field, status) => {
    updateBatcheAPI({ [field]: status }, { _id: data.id }).then(
      (res) => {
        dispatch(getAllBatchDataAction());
      },
      (error) => {
        dispatch(errorHandler(error));
      }
    );
  };

  const onEditClick = (data) => {
    let valuesObj = {
      course: courseSelectOption.find((x) => x.value === data.course.id),
      certificate_templete: templeteSelectOption.find(
        (x) => x.value === data.certificate_templete
      ),
      institute: instituteSelectOption.find((x) => x.value === data.institute),
      category: batchTypeOptions.find((x) => x.value === data.category),
      learning_mode: learningModeOptions.find(
        (x) => x.value === data.learning_mode
      ),
      fee_by: feeByTypeOptions.find((x) => x.value === data.fee_by),
      fee_collection_type: feeCollectionTypeOptions.find(
        (x) => x.value === data.fee_collection_type
      ),
      name: data.name,
      certificate_name: data.certificate_name,
      start_date: new Date(data.start_date),
      end_date: new Date(data.end_date),
      location: data.location,
      time: data.time, // Saperate by |
      duration: data.duration,
      fee: data.fee,
      fee_in_doller: data.fee_in_doller,
      certificate_prefix: data.certificate_prefix,
    };

    let allTrainOptions = [];
    data.trainers?.map((x) => {
      let oneTrainer = trainerSelectOption.find((e) => e.value === x.id);
      if (oneTrainer) {
        allTrainOptions.push(oneTrainer);
      }
    });

    valuesObj = { ...valuesObj, trainers: allTrainOptions };

    setValues(valuesObj);
    setIsEditing(true);
    setCurrentData(data);
  };

  props = {
    ...props,
    isDeleting,
    setIsDeleting,
    currentData,
    setCurrentData,
    onDeleteClick,
  };

  return (
    <React.Fragment>
      {isDeleting && <DeleteConfirmationPopUp {...props} />}
      <Row>
        <Colxx xxs="12">
          <div className="table-header-container">
            <span className="table-header-title">
              List of Batches
              <p className="fs-14px c_black-60p">
                Batches, on which students can register.
              </p>
            </span>
            <div>
              <button
                onClick={() => {
                  setIsAdding(true);
                }}
                className="shadow-box create-new-button"
              >
                Add New Batch
              </button>
            </div>
          </div>
          <Separator className="mb-5" />
        </Colxx>
      </Row>
      <Row>
        {isCertificateGenerate && (
          <GenerateBulkCertificates
            isOpen={isCertificateGenerate}
            setIsOpen={setIsCertificateGenerate}
          />
        )}
        {!isAdding && !isEditing && (
          <Colxx lg="12" xl="12">
            <Card className="d-flex mb-3 br-20px" key={`faqItem_1`}>
              <div className="position-absolute w-100percent d-flex justify-content-flex-end">
                <div className="w-303px mt-10px mr-25px">
                  <Select
                    options={courseSelectOption}
                    isClearable
                    onChange={(data) => {
                      if (data) {
                        dispatch(getAllBatchDataAction({ course: data.value }));
                      } else {
                        dispatch(getAllBatchDataAction());
                      }
                    }}
                  />
                </div>
              </div>
              <div className="d-flex flex-grow-1 min-width-zero">
                <div
                  className="card-body accordion-content pt-0"
                  style={{ maxWidth: "100%" }}
                >
                  <MDBDataTable
                    fixed
                    hover
                    entriesOptions={[25, 50, 100, 500, 1000]}
                    entries={25}
                    pagesAmount={4}
                    data={datatable}
                    materialSearch
                    // searchTop
                    fullPagination
                    striped
                    theadColor="dark"
                    searching={false}
                    pagingTop
                    scrollX={true}
                    // scrollY
                    maxHeight={1000}
                    searchBottom={false}
                    // barReverse={true}
                  />
                </div>
              </div>
            </Card>
            <button
              onClick={() => {
                setIsCertificateGenerate(true);
              }}
              className="shadow-box create-new-button bg-primary-variant"
            >
              Generate Bulk Certificates
            </button>
          </Colxx>
        )}

        {(isAdding || isEditing) && (
          <Colxx lg="12" xl="12">
            <Card className="d-flex mb-3 br-20px" key={`faqItem_1`}>
              <div className="d-flex flex-grow-1 min-width-zero">
                <div className="card-body accordion-content pt-0">
                  <Row className="mt-30px">
                    <Colxx lg="4" xl="4" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Select Course
                        <span className="c_B00020 ml-2px">*</span>
                      </label>
                      <Select
                        isClearable={true}
                        value={values.course}
                        onChange={(data) => {
                          let oneCourse = allCourses.find(
                            (x) => x.id === data?.value
                          );
                          setValues({
                            ...values,
                            course: data,
                            fee: oneCourse ? oneCourse.discounted_fee : 0,
                            fee_in_doller: oneCourse
                              ? oneCourse.discounted_fee_doller
                              : 0,
                          });
                        }}
                        options={courseSelectOption}
                      />
                    </Colxx>

                    <Colxx lg="4" xl="4" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Select Trainers
                        <span className="c_B00020 ml-2px">*</span>
                      </label>
                      <Select
                        isMulti
                        value={values.trainers}
                        onChange={(data) =>
                          setValues({ ...values, trainers: data })
                        }
                        options={trainerSelectOption}
                      />
                    </Colxx>

                    <Colxx lg="4" xl="4" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Select Institute
                        <span className="c_B00020 ml-2px">*</span>
                      </label>
                      <Select
                        value={values.institute}
                        onChange={(data) =>
                          setValues({ ...values, institute: data })
                        }
                        options={instituteSelectOption}
                      />
                    </Colxx>

                    <Colxx lg="3" xl="3" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Select Category
                        <span className="c_B00020 ml-2px">*</span>
                      </label>
                      <Select
                        value={values.category}
                        onChange={(data) =>
                          setValues({ ...values, category: data })
                        }
                        options={batchTypeOptions}
                      />
                    </Colxx>

                    <Colxx lg="3" xl="3" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Select Learning Mode
                        <span className="c_B00020 ml-2px">*</span>
                      </label>
                      <Select
                        value={values.learning_mode}
                        onChange={(data) =>
                          setValues({ ...values, learning_mode: data })
                        }
                        options={learningModeOptions}
                      />
                    </Colxx>

                    <Colxx lg="3" xl="3" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Fee Submitted By
                        <span className="c_B00020 ml-2px">*</span>
                      </label>
                      <Select
                        value={values.fee_by}
                        onChange={(data) =>
                          setValues({ ...values, fee_by: data })
                        }
                        options={feeByTypeOptions}
                      />
                    </Colxx>

                    <Colxx lg="3" xl="3" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Fee Collection Type
                        <span className="c_B00020 ml-2px">*</span>
                      </label>
                      <Select
                        value={values.fee_collection_type}
                        onChange={(data) =>
                          setValues({ ...values, fee_collection_type: data })
                        }
                        options={feeCollectionTypeOptions}
                      />
                    </Colxx>

                    <Colxx lg="4" xl="4" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Batch Name <span className="c_B00020 ml-2px">*</span>
                      </label>
                      <input
                        type={"text"}
                        className="form-control br-10px"
                        placeholder="ex. xyz"
                        value={values.name}
                        onChange={(data) =>
                          setValues({
                            ...values,
                            name: data.target.value,
                          })
                        }
                      />
                    </Colxx>

                    <Colxx lg="4" xl="4" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Certificate Name{" "}
                        <span className="c_B00020 ml-2px">*</span>
                      </label>
                      <input
                        type={"text"}
                        className="form-control br-10px"
                        placeholder="ex. xyz"
                        value={values.certificate_name}
                        onChange={(data) =>
                          setValues({
                            ...values,
                            certificate_name: data.target.value,
                          })
                        }
                      />
                    </Colxx>

                    <Colxx lg="4" xl="4" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Certificate Number Start's{" "}
                        <span className="c_B00020 ml-2px">*</span>
                      </label>
                      <input
                        type={"text"}
                        className="form-control br-10px"
                        placeholder="ex. TSP2022"
                        value={values.certificate_prefix}
                        onChange={(data) =>
                          setValues({
                            ...values,
                            certificate_prefix: data.target.value,
                          })
                        }
                      />
                    </Colxx>

                    <Colxx lg="3" xl="3" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Start Date <span className="c_B00020 ml-2px">*</span>
                      </label>
                      <DatePicker
                        selected={values.start_date}
                        dateFormat="dd-MMM-yy"
                        onChange={(d) =>
                          setValues({ ...values, start_date: d })
                        }
                      />
                    </Colxx>

                    <Colxx lg="3" xl="3" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        End Date <span className="c_B00020 ml-2px">*</span>
                      </label>
                      <DatePicker
                        selected={values.end_date}
                        dateFormat="dd-MMM-yy,"
                        onChange={(d) => setValues({ ...values, end_date: d })}
                      />
                    </Colxx>

                    <Colxx lg="2" xl="2" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Duration<span className="c_B00020 ml-2px">*</span>
                      </label>
                      <input
                        type={"text"}
                        className="form-control br-10px"
                        placeholder="ex. 2 Month"
                        value={values.duration}
                        onChange={(data) =>
                          setValues({
                            ...values,
                            duration: data.target.value,
                          })
                        }
                      />
                    </Colxx>

                    <Colxx lg="2" xl="2" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Fee (Rs.)<span className="c_B00020 ml-2px">*</span>
                      </label>
                      <input
                        type={"number"}
                        className="form-control br-10px"
                        placeholder="ex. 1234"
                        value={values.fee}
                        onChange={(data) =>
                          setValues({
                            ...values,
                            fee: data.target.value,
                          })
                        }
                      />
                    </Colxx>

                    <Colxx lg="2" xl="2" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Fee ($)<span className="c_B00020 ml-2px">*</span>
                      </label>
                      <input
                        type={"number"}
                        className="form-control br-10px"
                        placeholder="ex. 1234"
                        value={values.fee_in_doller}
                        onChange={(data) =>
                          setValues({
                            ...values,
                            fee_in_doller: data.target.value,
                          })
                        }
                      />
                    </Colxx>

                    <Colxx lg="4" xl="4" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Batch Time<span className="c_B00020 ml-2px">*</span>
                      </label>
                      <input
                        type={"text"}
                        className="form-control br-10px"
                        placeholder="ex. 6:20 AM to 8:30 AM | 4:00 PM to 6:00 PM"
                        value={values.time}
                        onChange={(data) =>
                          setValues({
                            ...values,
                            time: data.target.value,
                          })
                        }
                      />
                    </Colxx>

                    <Colxx lg="4" xl="4" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Location<span className="c_B00020 ml-2px">*</span>
                      </label>
                      <input
                        type={"text"}
                        className="form-control br-10px"
                        placeholder="ex. 1234"
                        value={values.location}
                        onChange={(data) =>
                          setValues({
                            ...values,
                            location: data.target.value,
                          })
                        }
                      />
                    </Colxx>
                    <Colxx lg="4" xl="4" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Select Certificate Templete
                        <span className="c_B00020 ml-2px">*</span>
                      </label>
                      <Select
                        value={values.certificate_templete}
                        onChange={(data) =>
                          setValues({ ...values, certificate_templete: data })
                        }
                        options={templeteSelectOption}
                      />
                    </Colxx>

                    <div className="d-flex justify-content-flex-end w-100percent">
                      <Button
                        onClick={() => {
                          setIsAdding(false);
                          setIsEditing(false);
                          setCurrentData(null);
                          setValues(defaultValues);
                        }}
                        size={"sm"}
                        color="dark"
                        className="br-20px c_white shadow-box create-new-button mt-30px"
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={onSubmitHandler}
                        size={"sm"}
                        color="secondry"
                        className="br-20px c_white shadow-box create-new-button mt-30px"
                      >
                        {isEditing ? "Update" : "Create"}
                      </Button>
                    </div>
                  </Row>
                </div>
              </div>
            </Card>
          </Colxx>
        )}
      </Row>
    </React.Fragment>
  );
};

export default CourseBatches;
