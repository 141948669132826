import { adminRoot } from "./defaultValues";

const studentMenu = [
  {
    id: "courses",
    icon: "iconsminds-shop-4",
    label: "Courses",
    to: `${adminRoot}`,
    // roles: [UserRole.Admin, UserRole.Editor],
  },
  {
    id: "certificates",
    icon: "iconsminds-air-balloon-1",
    label: "Certificates",
    to: `${adminRoot}/certificates`,
  },
  {
    id: "events",
    icon: "iconsminds-digital-drawing",
    label: "Events",
    to: `${adminRoot}/events`,
  },
  {
    id: "assignment",
    icon: "iconsminds-air-balloon-1",
    label: "Assignment",
    to: `${adminRoot}/assignment`,
  },
  {
    id: "self-learning",
    icon: "iconsminds-air-balloon-1",
    label: "Self Learning",
    to: `${adminRoot}/self-learning`,
  },
];

const instituteMenu = [
  {
    id: "courses",
    icon: "iconsminds-shop-4",
    label: "Courses",
    to: `${adminRoot}`,
    // roles: [UserRole.Admin, UserRole.Editor],
  },
  {
    id: "certificates",
    icon: "iconsminds-air-balloon-1",
    label: "Certificates",
    to: `${adminRoot}/certificates`,
  },
  {
    id: "events",
    icon: "iconsminds-digital-drawing",
    label: "Events",
    to: `${adminRoot}/events`,
  },
  {
    id: "assignment",
    icon: "iconsminds-air-balloon-1",
    label: "Assignment",
    to: `${adminRoot}/assignment`,
  },
  {
    id: "self-learning",
    icon: "iconsminds-air-balloon-1",
    label: "Self Learning",
    to: `${adminRoot}/self-learning`,
  },
];

const adminMenu = [
  {
    id: "dashboard",
    icon: "iconsminds-shop-4",
    label: "Dashboard",
    to: `${adminRoot}`,

    // roles: [UserRole.Admin, UserRole.Editor],
  },

  {
    id: "master-data",
    icon: "iconsminds-data-center",
    label: "Master Data",
    to: `${adminRoot}/master-data`,
    subs: [
      {
        icon: "iconsminds-male-female",
        label: "Users",
        to: `${adminRoot}/master-data/users`,
      },
      {
        icon: "iconsminds-el-castillo",
        label: "Institutes",
        to: `${adminRoot}/master-data/institutes`,
      },
      {
        icon: "iconsminds-office",
        label: "Companies",
        to: `${adminRoot}/master-data/companies`,
      },
      {
        icon: "simple-icon-pie-chart",
        label: "Course Category",
        to: `${adminRoot}/master-data/course-categories`,
        // roles: [UserRole.Admin],
      },
      {
        icon: "iconsminds-coding",
        label: "Technologies",
        to: `${adminRoot}/master-data/technologies`,
        // roles: [UserRole.Editor],
      },
      {
        icon: "simple-icon-tag",
        label: "Job Tags",
        to: `${adminRoot}/master-data/job-tags`,
        // roles: [UserRole.Editor],
      },
      {
        icon: "simple-icon-question",
        label: "FaQ's",
        to: `${adminRoot}/master-data/faqs`,
        // roles: [UserRole.Editor],
      },

      {
        icon: "simple-icon-doc",
        label: "Certificate Templates",
        to: `${adminRoot}/master-data/certificate-templetes`,
        // roles: [UserRole.Editor],
      },
      {
        icon: "simple-icon-paper-clip",
        label: "All Files",
        to: `${adminRoot}/master-data/all-files`,
        // roles: [UserRole.Editor],
      },
      {
        icon: "iconsminds-mens",
        label: "Team Members",
        to: `${adminRoot}/master-data/team-members`,
        // roles: [UserRole.Editor],
      },
      {
        icon: "simple-icon-call-in",
        label: "Enquires",
        to: `${adminRoot}/master-data/enquire`,
        // roles: [UserRole.Editor],
      },
      {
        icon: "iconsminds-quill-3",
        label: "Blog Topics",
        to: `${adminRoot}/master-data/blog-topics`,
        // roles: [UserRole.Editor],
      },
    ],
  },

  {
    id: "courses",
    icon: "iconsminds-digital-drawing",
    label: "Courses",
    to: `${adminRoot}/courses`,
  },

  {
    id: "batches",
    icon: "iconsminds-air-balloon-1",
    label: "Batches",
    to: `${adminRoot}/batches`,
  },
  {
    id: "students",
    icon: "iconsminds-student-male-female",
    label: "Stuidents",
    to: `${adminRoot}/students`,
  },
  {
    id: "blogs",
    icon: "iconsminds-quill-3",
    label: "Blogs",
    to: `${adminRoot}/blogs`,
  },

  // {
  //   id: "pre-recorded-courses",
  //   icon: "iconsminds-digital-drawing",
  //   label: "Pre-Recorded",
  //   to: `${adminRoot}/pre-recorded-courses`,
  // },

  // {
  //   id: "reviews",
  //   icon: "iconsminds-air-balloon-1",
  //   label: "Reviews",
  //   to: `${adminRoot}/reviews`,
  // },
];

export { studentMenu, adminMenu, instituteMenu };
