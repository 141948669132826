import React, { useEffect } from "react";
import { Row } from "reactstrap";
import IconCardsCarousel from "../../../../containers/dashboards/IconCardsCarousel";
import SalesChartCard from "../../../../containers/dashboards/SalesChartCard";
import RecentOrders from "../../../../containers/dashboards/RecentOrders";
import {
  Colxx,
  Separator,
} from "../../../../components/common/CustomBootstrap";

import { useSelector, useDispatch } from "react-redux";
import { getDashboardDataAction } from "../../../../redux/company/actions";

const Dashboard = (props) => {
  const dispatch = useDispatch();
  const dashboardData = useSelector((state) => state.company.dashboardData);

  useEffect(() => {
    dispatch(getDashboardDataAction());
  }, []);

  return (
    <>
      <Row>
        <Colxx lg="12" xl="12">
          <IconCardsCarousel values={dashboardData} />
        </Colxx>
      </Row>
    </>
  );
};

export default Dashboard;
