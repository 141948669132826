/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";

const SideOptions = () => {
  const containerRef = useRef();
  const [isOpen, setIsOpen] = useState(false);

  const handleDocumentClick = (e) => {
    if (isOpen) {
      const container = containerRef.current;
      if (container.contains(e.target) || container === e.target) {
        return;
      }
      setIsOpen(false);
    }
  };

  useEffect(() => {
    ["click", "touchstart"].forEach((event) =>
      document.addEventListener(event, handleDocumentClick, false)
    );

    return () => {
      ["click", "touchstart"].forEach((event) =>
        document.removeEventListener(event, handleDocumentClick, false)
      );
    };
  }, [isOpen]);

  return (
    <div ref={containerRef} className={`theme-colors ${isOpen ? "shown" : ""}`}>
      <div className="p-4">
        <p className="text-muted mb-2">Light Theme</p>

        <p className="text-muted mb-2">Dark Theme</p>
      </div>

      <a
        href="#section"
        className="theme-button"
        onClick={(e) => {
          e.preventDefault();
          setIsOpen(!isOpen);
        }}
      >
        {" "}
        <i className="simple-icon-magic-wand" />{" "}
      </a>
    </div>
  );
};

export default SideOptions;
