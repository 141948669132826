import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import {
  getAllBlogsDataAction,
  getAllUsersDataAction,
} from "../../../../../redux/master/actions";

const BlogFilterSection = (props) => {
  const [userSelectOptions, setUserSelectOptions] = useState([]);
  const [searchParams, setSearchParams] = useState({});
  const dispatch = useDispatch();
  const allUsers = useSelector((state) => state.master.allUsers);

  useEffect(() => {
    dispatch(getAllUsersDataAction());
  }, []);

  useEffect(() => {
    let allRows = [];
    allUsers?.map((x) => {
      allRows.push({
        label: (
          <span className="">
            <strong>
              {x.first_name} {x.last_name}
            </strong>
            <br /> {x.username}
          </span>
        ),
        value: x.id,
        email: x.username,
        name: x.first_name + " " + x.last_name,
      });
    });
    setUserSelectOptions(allRows);
  }, [allUsers]);

  useEffect(() => {
    var newObj = {};
    Object.keys(searchParams).map((key) => {
      if (searchParams[key]) {
        if (searchParams[key].value.toString().length > 0)
          newObj[key] = searchParams[key].value;
      }
    });
    if (!newObj.search || newObj.search?.length > 2) {
      dispatch(getAllBlogsDataAction(newObj));
    }
  }, [searchParams]);

  const customFilter = (option, searchText) => {
    if (
      option.data.email.toLowerCase().includes(searchText.toLowerCase()) ||
      option.data.name.toLowerCase().includes(searchText.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <React.Fragment>
      <div className="d-flex">
        <div className="student-filter-section">
          <div className="w-250px mb-10px mr-10px">
            <label className="input-label fw-400 fs-14px">Select Author</label>
            <Select
              options={userSelectOptions}
              isClearable
              value={searchParams.user_id}
              onChange={(data) => {
                setSearchParams({
                  ...searchParams,
                  user_id: data,
                  search: "",
                });
              }}
              filterOption={customFilter}
            />
          </div>

          <div className="w-200px mb-10px mr-10px">
            <label className="input-label fw-400 fs-14px">Search</label>
            <input
              type={"text"}
              className="form-control br-10px"
              placeholder="Search..."
              value={searchParams.search ? searchParams.search?.value : ""}
              onChange={(data) =>
                setSearchParams({
                  ...searchParams,
                  search: { value: data.target.value },
                })
              }
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BlogFilterSection;
