/* eslint-disable import/no-cycle */
/* SETTINGS */

/* AUTH */
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGOUT_USER = "LOGOUT_USER";
export const SET_USER_TYPE = "SET_USER_TYPE";
export const SET_ERRRO_MSG = "SET_ERRRO_MSG";
export const SET_API_PROGRESS = "SET_API_PROGRESS";

/* MENU */
export const MENU_SET_CLASSNAMES = "MENU_SET_CLASSNAMES";
export const MENU_CONTAINER_ADD_CLASSNAME = "MENU_CONTAINER_ADD_CLASSNAME";
export const MENU_CLICK_MOBILE_MENU = "MENU_CLICK_MOBILE_MENU";
export const MENU_CHANGE_DEFAULT_CLASSES = "MENU_CHANGE_DEFAULT_CLASSES";
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  "MENU_CHANGE_HAS_SUB_ITEM_STATUS";

/* MASTER DATA */
export const GET_LIST_OF_COMPANIES = "GET_LIST_OF_COMPANIES";
export const GET_LIST_OF_COURSE_CATEGORIES = "GET_LIST_OF_COURSE_CATEGORIES";
export const GET_LIST_OF_TECHNOLOGIES = "GET_LIST_OF_TECHNOLOGIES";
export const GET_LIST_OF_JOB_TAGS = "GET_LIST_OF_JOB_TAGS";
export const GET_LIST_OF_FAQS = "GET_LIST_OF_FAQS";
export const GET_LIST_OF_TEMPLETES = "GET_LIST_OF_TEMPLETES";
export const GET_LIST_OF_FILES = "GET_LIST_OF_FILES";
export const GET_LIST_OF_TEAM_MEMBERS = "GET_LIST_OF_TEAM_MEMBERS";
export const GET_LIST_OF_BLOG_TOPICS = "GET_LIST_OF_BLOG_TOPICS";
export const SET_ONE_COURSE_DETAILS = "SET_ONE_COURSE_DETAILS";
export const SET_ALL_COURSES_LIST = "SET_ALL_COURSES_LIST";

export const SET_ONE_BATCH_DETAILS = "SET_ONE_BATCH_DETAILS";
export const SET_ALL_BATCH_LIST = "SET_ALL_BATCH_LIST";

export const SET_ONE_ENQUIRE_DETAILS = "SET_ONE_ENQUIRE_DETAILS";
export const SET_ALL_ENQUIRES_LIST = "SET_ALL_ENQUIRES_LIST";

export const SET_ONE_USER_DETAILS = "SET_ONE_USER_DETAILS";
export const SET_ALL_USER_LIST = "SET_ALL_USER_LIST";

export const SET_ONE_INSTITUTE_DETAILS = "SET_ONE_INSTITUTE_DETAILS";
export const SET_ALL_INSTITUTE_LIST = "SET_ALL_INSTITUTE_LIST";

export const SET_ONE_STUDENT_DETAILS = "SET_ONE_STUDENT_DETAILS";
export const SET_ALL_STUDENT_LIST = "SET_ALL_STUDENT_LIST";

export const GET_ONE_BLOG_DETAILS = "GET_ONE_BLOG_DETAILS";
export const GET_ALL_BLOG_LIST = "GET_ALL_BLOG_LIST";

export const SET_ONE_STUDENT_ENROLLMENT_DETAILS =
  "SET_ONE_STUDENT_ENROLLMENT_DETAILS";
export const SET_ALL_STUDENT_ENROLLMENT_LIST =
  "SET_ALL_STUDENT_ENROLLMENT_LIST";

export * from "./menu/actions";
export * from "./auth/actions";
